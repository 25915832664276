import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { LegalContentType } from 'applicationConstants';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { yiluCookies, yiluSession } from 'utils';

import { useLegalModalContext, useUserProfileContext } from 'context';

const Tracking: React.FC = () => {
  const { t } = useTranslation();
  const consentState = yiluCookies.getConsentFromCookie();
  const [cookieBannerState, setCookieBannerState] = useState(() => ({
    consent: {
      statistics: false,
      personalization: false,
      comfort: false,
      ...consentState?.consent,
      necessary: true,
    },
    isActionTriggered: false,
  }));
  const { openLegalModal, closeLegalModal, isLegalModalShown } = useLegalModalContext();
  const { upsertUserProfile } = useUserProfileContext();

  const statisticsConsentRef = useRef<HTMLInputElement>(null);
  const personalizationConsentRef = useRef<HTMLInputElement>(null);
  const comfortConsentRef = useRef<HTMLInputElement>(null);

  const { consent, isActionTriggered } = cookieBannerState;
  const { necessary, statistics, personalization, comfort } = consent;

  const hiddenHeadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isLegalModalShown && hiddenHeadingRef.current) {
      hiddenHeadingRef.current.focus();
    }
  }, [isLegalModalShown]);

  useEffect(() => {
    if (isActionTriggered) {
      yiluSession.setIsConsentPopupDisplayedOnce();
      yiluCookies.saveConsentToCookie({ ...cookieBannerState, consent });
      setCookieBannerState((prevState) => ({ ...prevState, isActionTriggered: false }));
      closeLegalModal();
      upsertUserProfile();
    }
  }, [consent, cookieBannerState, isActionTriggered, closeLegalModal, upsertUserProfile]);

  return (
    <div
      className="tracking-preferences"
      role="dialog"
      aria-modal="true"
      aria-labelledby="tracking-modal-title"
    >
      <h2 className="tracking-preferences--screenReaderLabel" ref={hiddenHeadingRef} tabIndex={-1}>
        {t('cookiesBanner.trackingPreferences')}
      </h2>
      <div className="tracking-preferences__desc">
        <ElementsSDK.Typography variant="p2">{t('Cookie notification')}</ElementsSDK.Typography>
        <ElementsSDK.Typography variant="p2">
          {t('cookiesBanner.trackingPreferences.description')}
        </ElementsSDK.Typography>
      </div>

      <div className="checkbox-container">
        <ElementsSDK.Checkbox
          className="tracking-preferences--checkbox"
          checked={necessary}
          onChange={() => {
            setCookieBannerState((prevState) => ({
              ...prevState,
              consent: { ...prevState.consent, necessary: true },
            }));
          }}
          testid="my-checkbox"
          disabled
          text={t('tracking.modal.checkbox.necessary')}
        />
        <ElementsSDK.Checkbox
          className="tracking-preferences--checkbox"
          checked={statistics}
          onChange={(value: boolean) => {
            setCookieBannerState((prevState) => ({
              ...prevState,
              consent: { ...prevState.consent, necessary: true, statistics: value },
            }));

            if (!value) {
              if (personalizationConsentRef.current!.checked) {
                personalizationConsentRef.current!.click();
              }
              if (comfortConsentRef.current!.checked) {
                comfortConsentRef.current!.click();
              }
            }
          }}
          testid="my-checkbox"
          text={t('tracking.modal.checkbox.statistics')}
          inputRef={statisticsConsentRef}
        />
        <ElementsSDK.Checkbox
          className="tracking-preferences--checkbox"
          checked={personalization}
          onChange={(value: boolean) => {
            setCookieBannerState((prevState) => ({
              ...prevState,
              consent: {
                ...prevState.consent,
                necessary: true,
                personalization: value,
              },
            }));
            if (value) {
              if (!statisticsConsentRef.current!.checked) {
                statisticsConsentRef.current!.click();
              }
            } else {
              if (comfortConsentRef.current!.checked) {
                comfortConsentRef.current!.click();
              }
            }
          }}
          testid="my-checkbox"
          text={t('tracking.modal.checkbox.personalization')}
          inputRef={personalizationConsentRef}
        />
        <ElementsSDK.Checkbox
          className="tracking-preferences--checkbox"
          checked={comfort}
          onChange={(value: boolean) => {
            setCookieBannerState((prevState) => ({
              ...prevState,
              consent: {
                ...prevState.consent,
                necessary: true,
                comfort: value,
              },
            }));
            if (value) {
              if (!statisticsConsentRef.current!.checked) {
                statisticsConsentRef.current!.click();
              }

              if (!personalizationConsentRef.current!.checked) {
                personalizationConsentRef.current!.click();
              }
            }
          }}
          testid="my-checkbox"
          text={t('tracking.modal.checkbox.comfort')}
          inputRef={comfortConsentRef}
        />
      </div>
      <div className="secondary-actions-container">
        <ElementsSDK.Accordion
          label={{
            open: t('cookiesBanner.trackingPreferences.hideDetails'),
            closed: t('cookiesBanner.trackingPreferences.showDetails'),
          }}
          showIcon
          testid="dropdown-option-2"
          labelVariant="h5"
        >
          <div className="tracking-preferences__desc">
            <ElementsSDK.Typography variant="h4">
              {t('tracking.modal.checkbox.necessary')}
            </ElementsSDK.Typography>
            <ElementsSDK.Typography variant="p2">
              {t('cookiesBanner.trackingPreferences.necessaryDescription')}
            </ElementsSDK.Typography>
          </div>
          <div className="tracking-preferences__desc">
            <ElementsSDK.Typography variant="h4">
              {t('tracking.modal.checkbox.statistics')}
            </ElementsSDK.Typography>
            <ElementsSDK.Typography variant="p2">
              {t('cookiesBanner.trackingPreferences.marketingDescription')}
            </ElementsSDK.Typography>
            <ElementsSDK.Typography
              variant="p2"
              className="tracking-preferences__cookie-desc"
              dangerouslySetInnerHTML={{
                __html: t('cookiesBanner.trackingPreferences.marketingCookieDescription'),
              }}
            />
          </div>
          <div className="tracking-preferences__desc">
            <ElementsSDK.Typography variant="h4">
              {t('tracking.modal.checkbox.personalization')}
            </ElementsSDK.Typography>
            <ElementsSDK.Typography variant="p2">
              {t('cookiesBanner.trackingPreferences.personalizationUserProfileDescription')}
            </ElementsSDK.Typography>
          </div>
          <div className="tracking-preferences__desc">
            <ElementsSDK.Typography variant="h4">
              {t('tracking.modal.checkbox.comfort')}
            </ElementsSDK.Typography>
            <ElementsSDK.Typography variant="p2">
              {t('tracking.modal.checkbox.comfortDescription')}
            </ElementsSDK.Typography>
          </div>
          <ElementsSDK.Typography variant="p2">
            {t('Tracking modal - Outro')}{' '}
            <ElementsSDK.Link
              className="tracking-modal-outro-link"
              inline
              onClick={() => {
                openLegalModal(LegalContentType.PRIVACY, t('Privacy'), true);
              }}
            >
              {t('Tracking modal - Outro link')}
            </ElementsSDK.Link>
          </ElementsSDK.Typography>
        </ElementsSDK.Accordion>

        <ElementsSDK.Link
          Icon={ElementsSDK.Icon.ArrowRight}
          onClick={() => {
            openLegalModal(LegalContentType.IMPRINT, t('Imprint'), true);
          }}
        >
          <ElementsSDK.Typography variant="p2" style={{ fontWeight: 'unset' }}>
            {t('Imprint')}
          </ElementsSDK.Typography>
        </ElementsSDK.Link>
      </div>

      <div className="primary-actions-container">
        <ElementsSDK.Button
          className="btn-cta"
          outline
          onClick={() =>
            setCookieBannerState((prevState) => ({ ...prevState, isActionTriggered: true }))
          }
          data-testid="tracking-preferences-confirm-selection-button"
        >
          {t('cookiesBanner.trackingPreferences.confirmSelection')}
        </ElementsSDK.Button>
        <ElementsSDK.Button
          className="btn-cta"
          onClick={() => {
            if (!statisticsConsentRef.current!.checked) {
              statisticsConsentRef.current!.click();
            }

            if (!personalizationConsentRef.current!.checked) {
              personalizationConsentRef.current!.click();
            }

            if (!comfortConsentRef.current!.checked) {
              comfortConsentRef.current!.click();
            }

            setCookieBannerState((prevState) => ({ ...prevState, isActionTriggered: true }));
          }}
          data-testid="tracking-preferences-select-all-button"
        >
          {t('cookiesBanner.trackingPreferences.selectAll')}
        </ElementsSDK.Button>
      </div>
    </div>
  );
};

export default Tracking;
