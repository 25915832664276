import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React, { FC } from 'react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import './styles.scss';

export type ImageSliderProps = {
  images?: {
    imageUrl: string;
    alt: string;
  }[];
};

export const ImageSlider: FC<ImageSliderProps> = ({ images }) => {
  const hasMultipleImages = images && images?.length > 1;
  return (
    <div className={`yilu-ImageSlider-con`}>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={15}
        pagination={{
          clickable: true,
        }}
        loop
        modules={[Pagination, Navigation]}
        className="yilu-ImageSlider"
        navigation={
          hasMultipleImages
            ? { nextEl: '.yilu-ImageSlider--arrow-right', prevEl: '.yilu-ImageSlider--arrow-left' }
            : undefined
        }
      >
        {images?.map(({ imageUrl, alt }, index) => (
          <SwiperSlide key={index}>
            <img src={imageUrl} alt={alt || ''} loading="lazy" />
          </SwiperSlide>
        ))}
      </Swiper>
      {hasMultipleImages && (
        <>
          <button type="button" className="yilu-ImageSlider--arrow-left yilu-ImageSlider--arrow">
            <ElementsSDK.Icon.ArrowRightChevron />
          </button>
          <button type="button" className="yilu-ImageSlider--arrow-right yilu-ImageSlider--arrow">
            <ElementsSDK.Icon.ArrowRightChevron />
          </button>
        </>
      )}
    </div>
  );
};
